



















import Vue from 'vue'
import { DispatchingCredit } from '../../../../../store/models/DispatchingCredit'

export default Vue.extend({
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'N° transaction',
          field: 'transaction.reference'
        },
        {
          label: 'Ligne de crédit',
          field: 'creditLine.name'
        },
        {
          label: 'Nom initiateur',
          field: 'initiator.fullname'
        },
        {
          label: 'Date/Heure',
          field: 'createdAt',
          datetime: true
        },
        {
          label: 'Montant',
          field: 'amount',
          currency: true
        },
        {
          label: 'Numéro de téléphone',
          field: 'beneficiary.phone'
        },
        {
          label: 'Opérateur Mobile',
          field: 'otm'
        }
        // {
        //   label: 'Actions',
        //   field: '_actions',
        //   align: 'left'
        // }
      ]
    }
  },

  computed: {
    dispatchingCredits() {
      return DispatchingCredit.query()
        .withAll()
        .get()
    }
  },

  created() {
    this.loading = true
    DispatchingCredit.api()
      .fetchForOrganization()
      .then(() => {
        this.loading = false
      })
      .catch((error: Error) => {
        console.error(error)
        this.loading = false
      })
  }
})
